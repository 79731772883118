.data-table {
    padding: 50px 10%;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.6);
    background-color: #1d1d1e;
  }
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .data-table .data-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    padding-right: 30px;
  }
  
  .data-table .data-search input {
    padding: 6px 10px;
    background: none;
    border: none;
    border-bottom: 2px solid #D02129;
    color: #fff;
    background-color: #181818;
    width: 200px;
    border-radius: 0;
    font-size: 16px;
    outline: none;
  }
  
  .data-table .data-search input::placeholder {
    color: #ccc;
  }
  
  .data-table .data-search .magnifying-glass-icon {
    position: absolute;
    top: 60%;
    right: 40px;
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
  }
  