.welcome-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ff8a00, #e52e71);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.swipe-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .welcome-text {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.events-content {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  &.swipe-in {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
}
