.register-container {
  background-color: #1d1d1e;
  width: 100%;
  padding: 5rem;
  font-family: "Klima", sans-serif;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
}

.form-group-half {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

button {
  padding: 10px;
  background-color: #D02129;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.register-event {
  max-width: 900px;
  margin: 0 auto;
  background-color: #1d1d1e;
  color: #fff;
}

h2 {
  font-size: 50px;
  font-weight: 600;
}

p {
  font-size: 15px;
  color: #7B7B7B;
  margin-bottom: 30px;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.checkbox-group,
.radio-group {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  label {
    margin-right: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    input[type="checkbox"] {
      margin: 0;
    }
  }
}

input[type="text"],
input[type="number"],
input[type="tel"],
textarea,
input[type="email"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: #7B7B7B;
  padding: 1rem;
  height: 40px;
  margin-top: 1rem;

  &:focus {
    color: #fff;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea#specialRequest {
  overflow-x: auto;
  overflow-y: auto;
}

input[type="file"]::file-selector-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff1a;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  margin: 1rem 1rem;

  &:active {
    transform: scale(0.9);
  }
}

@media (max-width: 1000px) {
  .register-container {
    padding: 3rem;
  }

  .form-group-half {
    width: 100%;
    margin-top: 0.5rem;
  }
  .form-group-row {
    flex-direction: column;
  }  
  .register-event {
    padding: 3rem;
  }

  h2 {
    font-size: 40px;
  }

  p {
    font-size: 14px;
  }
}
