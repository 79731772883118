@font-face {
    font-family: 'Klima';
    src: url('../font/klima-black.otf') format('otf'),
  }

.buy-container {
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  .img-buy {
    transition: transform 0.3s ease;
    width: 90%;
    height: auto;
  }
  
  .img-buy:hover {
    transform: scale(1.1);
  }
  .attribute {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    align-items: center;
  }
}
.buy-container2 {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 1em;
  display: none;
  .img-buy {
    transition: transform 0.3s ease;
    width: 100%;
    height: auto;
  }
  
  .img-buy:hover {
    transform: scale(1.1);
  }
  .attribute {
    color: #000;
    font-size: 7px;
    text-decoration: none;
    align-items: center;
  }
}
.sponsorship {
  position: absolute;
  bottom: -100px;
  right: 40px;
  display: flex;
  z-index: 120;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}

.sponsorship p {
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  color: white;
}

.sponsorship img {
  height: 40px;
  width: auto;
  align-self: flex-start;
}

.home-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: var(--background);
  }

  .home-hero {
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1d1d1e;
  }
  .home-navbar {
    width: 100%;
    display: flex;
    z-index: 1000;
    position: relative;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    justify-content: space-between;
  }
  .home-burger-menu {
    display: none;
    align-items: center;
    justify-content: center;
  }
  .home-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .home-hero-content {
    width: 100%;
    height: 823px;
    z-index: 100;
    position: relative;
    max-width: 1000px;
    padding: 1em;
  }
  .hero-container {
    position: relative;
    width: 630px;
    height: 35vh;
    top: -3rem;
  }
  
  .home-text07 {
    color: rgb(255, 255, 255);
    width: 630px;
    z-index: 100;
    font-size: 80px;
    font-family: 'Klima', sans-serif;
    font-weight: 900;
    line-height: 1.1;
    position: relative;
  }
  
  .home-text11 {
    color: rgb(255, 255, 255);
    z-index: 100;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    position: relative;
  }
  
  .home-text12 {
    font-size: 15px;
  }
  
  .home-button {
    list-style: none;
    width: auto;
    max-width: 30%;
    margin-top: 1em;
    white-space: nowrap;
    height: 54px;
    z-index: 100;
    position: relative;
    background-color: #d02129;
    letter-spacing: 3px;
    border: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
  
    li {
      padding: 2rem;
  
      a {
        font-size: 15px;
        text-decoration: none;
        color: white;
        transition: text-decoration 0.3s ease;
      }
    }
  
    &:hover {
      letter-spacing: 8px;
      transition: letter-spacing 0.3s ease;
  
      li {
        transform: translateX(-3px);
        transition: transform 0.3s ease;
  
        &:nth-child(2) {
          transform: translateX(3px);
        }
      }
    }
  
    &:not(:hover) {
      li {
        transition: transform 0.3s ease;
  
        &:nth-child(2) {
          transform: translateX(0);
        }
      }
    }
  }
  
  .home-container2 {
    color: #ffffff;
    padding: 1rem;
    height: auto;
    display: flex;
    position: absolute;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    background-color: #1d1d1e;
    align-items: center;
    flex-direction: column;
    border-radius: 30px; 
    width: 100%;
    max-width: 100%;
    bottom: -10rem;
  }
  
  .home-text14 {
    font-size: 50px;
    align-self: center;
    font-family: 'Klima', sans-serif;
    font-weight: 600;
  }
  .home-text15 {
    font-size: 14px;
    align-self: center;
    color: #929292;
  }
  .home-container3 {
    width: 872px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .see-all {
    ul {
      padding-top: 1rem;
      list-style: none;
    }
  
    li {
      cursor: pointer;
    }
  
    a {
      position: relative;
      appearance: none;
      text-decoration: none;
      color: #d02129;
    } 
  
    a::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #d02129;
      transition: width 0.3s ease;
    }
  
    a:hover::after {
      width: 100%;
    }
  }
}
  .home-container4 {
    flex: 0 0 auto;
    width: 767px;
    height: 149px;
    display: flex;
    padding-top: 2rem;
    justify-content: center;
  }
  .home-image01 {
    width: 219px;
    height: 118px;
    align-self: center;
    object-fit: cover;
    border-color: #d02129;
    border-width: 3px;
  }
  .home-social-icons {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    z-index: 110;
    padding: 30px;
  }
  
  .home-social-icons a {
    margin-bottom: 20px;
    color: white;
    font-size: 24px;
    transition: color 0.3s, transform 0.3s;
  }
  
  .home-social-icons a:hover {
    transform: scale(1.5);
    color: #888888;
  }
  
  .home-image04 {
    width: 90%;
    height: auto;
    z-index: -1;
    position: absolute;
    object-fit: cover;
    right: -200px;
    bottom: 80px;
  }
  
  .home-background1 {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  
  .home-background1 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
  
  .home-background1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }

  @media (max-width: 840px) {
    .home-text07 {
      font-size: 50px;
    }
    .home-text11{
      font-size: 10px;
    }
    .home-image04 {
      display: none;
    }
    .home-text12  {
      font-size: 10px;
    }
    .hero-container {
      padding: 2rem;
    }
  }
  @media (max-width: 1270px) {
    .home-social-icons {
      display: none;
    }
  }
  
  @media (max-width: 1110px) {
    .home-social-icons,
    .home-container2,
    .home-image04 {
      display: none;
    }
    .sponsorship {
      right: 0;
      position: relative
    }
  }

  @media (max-width: 768px) {
    .home-text14 {
      font-size: 30px;
    }
    .home-text15 {
      font-size: 10px;
    }
  }

  
  @media (max-width: 700px) {
    .buy-container2 {
      display: block;
    }
    .buy-container {
      display: none;
    }
  }

  