@font-face {
    font-family: 'Paladins';
    src: url('../font/paladinsstraight.ttf') format('ttf');
  }
  
  .loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
    background-image: url('../img/14.jpg');
    background-size: cover;
    background-blend-mode: overlay;
  }
  
  .loading-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #141e30, #243b55);
    opacity: 0.9;
    z-index: -1;
  }
  
  
  .wave-container {
    display: flex;
  }
  
  .wave {
    display: flex;
    align-items: center;
  }
  
  .loading-ball {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    animation: bounce 1s ease-in-out infinite;
    position: relative;
    overflow: hidden;
    background-color: #ffcc00;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .loading-ball::before {
    content: 'R';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    font-family: 'Paladins Straight Regular', sans-serif;
  }
  
  .loading-ball::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ffffcc;
    z-index: -1;
  }
  
  .loading-bar-container {
    width: 200px;
    height: 10px;
    background-color: #ffffff;
    margin-top: 20px;
    font-family: Poppins;
  }
  
  .loading-bar {
    height: 100%;
    background: linear-gradient(to right, #ff0000, #ff8000, #ffff00, #00ff00, #00ffff, #0000ff, #8000ff, #ff00ff);
    background-size: 800% 100%;
    animation: rainbow 4s linear infinite;
    transition: width 0.3s ease-in-out;
  }
  
  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .loading-text {
    margin-top: 5px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
      animation-timing-function: ease-in;
    }
    50% {
      transform: translateY(-50px);
      animation-timing-function: ease-out;
    }
  }
  