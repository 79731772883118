.sanction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../img/1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    .title {
      font-size: 50px;
      text-align: center;
      margin-bottom: 20px;
      color: #1d1d1e;
      top: -100px;
      position: relative;
    }
  
    .slider {
      width: 80%;
      height: 100%; 
      position: relative;
      overflow: hidden;
      top: -100px;
      box-shadow: 0px 10px 7px 6px rgba(0, 0, 0, 0.15);
    }
  
  .slide {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.3s ease;
  }
  
  .left-side,
  .right-side {
    flex: 1;
    display: flex;
  }
  
  .left-side {
    background-color: #f1f1f1;
    padding: 3em;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #1d1d1e;
  }
  
  p, span {
    font-size: 12px;
    color: #929292;
    max-width: 100%;
    text-align: justify;
    margin-bottom: 5px;
  }
  .margin-left {
    margin-left: 10px;
  }
  .right-side {
    background-color: #e1e1e1;
    position: relative;
  }
  
  .right-side img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    justify-content: center;
  }
  
  
  .slide.active {
    transform: translateX(0);
  }
  
  .slide:not(.active) {
    transform: translateX(100%);
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    width: 50%;
    padding: 5rem;
  }
  
  .prev-button,
  .next-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .arrow-icon {
    color: #000;
    font-size: 24px;
  }
  
  .prev-button:hover,
  .next-button:hover {
    background-color: #f2f2f228;
  }
  
  a {
    color: #1d1d1e;
    transition: color 0.3s ease;
    text-decoration: none;
  }
  
  a:hover {
    color: #d02129;
  }
}


@media (max-width: 1000px) {
 .sanction-container {
        display: none;
      }
}
