
.card {
  background-color: var(--card-color);
  width: max-content;
  border-radius: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;

  .imgBx {
    img {
      width: 12rem;
      height: 12rem;
      border-radius: 1rem;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
    }

    p {
      span {
        font-weight: bold;
      }
    }
  }

  &.expanded {
    height: auto;
  }
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .card {
    .card-image {
      img {
        width: 10rem;
        height: 10rem;
      }
    }

    &-content {
      .card-heading {
        .card-series {
          font-size: 0.6rem;
        }
        .card-top {
          font-size: 0.6rem;
        }
      }

      .card-details {
        font-size: 0.6rem;
      }

      .card-sub-details {
        span {
          font-size: 0.6rem;
        }
      }
    }
  }
}
