.footer {
  background-color: #181818;
  color: white;
  padding: 20rem 20px 50  px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
  }

  .footer-logo {
    margin-bottom: 20px;

    img {
      max-width: 200px;
    }
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-links {
    display: flex;
    gap: 50px;
    margin-bottom: 2rem;
    a {
      color: white;
      text-decoration: none;
      font-size: 14px;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .footer-divider {
    width: 100%;
    height: 1px;
    background-color: #666;
    margin: 40px 0;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding-top: 30px;
    border-top: 2px solid #1d1d1d;
    width: 90%;
  
    .footer-social {
      display: flex;
      align-items: center;
    }
  
    .all-rights {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
    
    p {
      font-size: 12px;
      color: lightgrey;
      margin: 0;
    }
    
    .footer-social a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #1d1d1e;
      color: white;
      margin-right: 10px;
      transition: background-color 0.3s ease-in-out;
    }
    
    .footer-social a:hover {
      background-color: #666;
  }
}
  
  // Responsive styling
  @media (max-width: 768px) {
    .footer-content {
      align-items: flex-start;
    }

    .footer-social {
      justify-content: center;
    }
  }
}
  @media (max-width: 840px) {
    .footer .footer-bottom {
      flex-direction: column;
    }
    .footer .footer-bottom p {
      margin-top: 2rem;
      text-align: center;
    }
    .footer .footer-links {
      gap: 20px;
      flex-direction: column;
      text-align: center;
    }
}
