@import "~swiper/swiper.scss";
@import "~swiper/components/navigation/navigation.scss";
@import "~swiper/components/effect-coverflow/effect-coverflow.scss";
@import "~swiper/components/pagination/pagination.scss";
.swiper-swiper {
    background-color: #111111;
  .swiper-container {
    width: 100%;
    height: 100vh;

    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        width: 50%;
        height: 90%;
        justify-content: center;
        align-items: center;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        outline: none;
      }
    }

    .custom-navigation {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);

      .custom-navigation-prev,
      .custom-navigation-next {
        width: 30px;
        height: 30px;
        font-size: 24px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
      }
    }

    .swiper-pagination {
      bottom: 10px;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 100;
      padding-bottom: 5em;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid white; // Add a white border to all bullets
        transition: 0.3s;

        &-active {
          background-color: white;
          border: 1px solid white; // Add a white border to the active bullet
          opacity: 1;
        }
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }
}
