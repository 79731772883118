.register-club {
    background-color: #fff;
    width: 100%;
    padding: 5rem;
    font-family: "Klima", sans-serif;
    .club-application {
      max-width: 900px;
      margin: 0 auto;
      border-bottom: 1px solid #7B7B7B;
      h2 {
        color: #1d1d1e;
        font-size: 30px;
        text-align: center;
      }
      p {
        margin-bottom: 2em;
        line-height: 1.5;
        color: #7B7B7B;
      }
    }
    .form-club {
      max-width: 900px;
      margin: 0 auto;
      background-color: transparent;
      margin-top: 3rem;
    iframe {
      z-index: -5;
    }
    .club-header {
      margin-bottom: 2rem;
      h2 {
        font-size: 50px;
        font-weight: 600;
        color: #1d1d1e;
      }
      
      p {
        font-size: 15px;
        color: #7B7B7B;
      }
    }
      .sent-message {
        color: #1d1d1e;
        padding: 10px;
        opacity: 1;
        font-size: 10px;
        transition: opacity 0.5s ease;
        text-align: center;
  
        &.fade-out {
          opacity: 0;
        }
      }
    }
  
    .form-group {
      margin-bottom: 20px;
      p {
        color: #7B7B7B;
        margin: 20px 0 10px 0;
      }
    }
    
    label {
      color: #1d1d1e;
      font-weight: bold;
    }
  
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 6px;
      box-sizing: border-box;
      background-color: #F3F3F3;
      color: #7B7B7B;
      padding: 1rem;
      height: 40px;
      margin-top: 1rem;
      border:  none;
  
      &:focus {
        border: none;
        color: #1d1d1e;
      }
    }
    
  
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    .half-width {
      width: 50%;
    }
  
    .button-group {
      display: flex;
      align-items: center;
      margin-right: 10px;
  
      label {
        display: flex;
        align-items: center;
        font-weight: 300;
  
        input[type="radio"] {
          margin-right: 5px;
        }
      }
    }
  
    button[type="submit"] {
      padding: 10px;
      background-color: #D02129;
      color: #fff;
      border: none;
      font-weight: 600;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
    }
  
    .horizontal-group {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      label {
        flex: 0 0 150px;
      }
  
      input[type="text"],
      input[type="number"],
      input[type="tel"],
      textarea {
        flex: 1;
        border: none;
        background-color: #F3F3F3;
      }
    }
    .bigger-textarea {
        height: 10vh;
        overflow: hidden;
        max-height: 20vh;
    }
    .smaller-textarea{
        overflow: hidden;
    }
  }
  .upload-group input[type="file"] {
    color: #7B7B7B;
  }
  .button-group input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #1d1d1e;
  }
  .upload-group input[type="file"]::file-selector-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #1d1d1e;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: transform 0.3s ease;
    margin: 1rem 1rem;

    &:active {
      transform: scale(0.9);
    }
  }

  @media (max-width: 1000px) {
    .form-group {
      width: 100%;
      margin-top: 0.5rem;
    }
    .horizontal-group {
      flex-direction: column;
    }  
  }
  