.partners {
    background: #f3f3f3;
    padding: 5em;
    height: auto;
  
    .header {
      text-align: center;
  
      h1 {
        color: #1d1d1e;
        font-size: 50px;
      }
  
      h2 {
        color: #929292;
        font-size: 15px;
        font-weight: 300;
      }
    }
  
    .image-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 20px auto;
  
    img {
      width: 20%;
      height: auto;
      margin: 10px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
}
  
    @media (max-width: 768px) {
      .image-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            margin-bottom: 50px;
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
  

  .partners2 {
    background: #1d1d1e;
    padding: 5em;
    height: auto;
  
    .header {
      text-align: center;
  
      h1 {
        color: #f3f3f3;
        font-size: 50px;
      }
  
      h2 {
        color: #929292;
        font-size: 15px;
        font-weight: 300;
      }
    }
  
    .image-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 20px auto;
  
      img {
        width: calc(100% / 3 - 100px);
        height: auto;
        margin: 10px;
        transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
        padding-bottom: 2em;
        filter: grayscale(100%);
  
        &:hover {
          filter: grayscale(0%);
          transform: scale(1.1);
        }
      }
    }
  
    @media (max-width: 768px) {
      .image-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        img {
          margin-bottom: 50px;
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
  
  @media (max-width: 840px) {
    .partners,
    .partners2 {
      .header {
        h1 {
          font-size: 30px;
          text-align: left;
        }
      }
    }
  }