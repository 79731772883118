.slick-prev:hover,
.slick-prev:focus,
.slick-prev:active,
.slick-next:hover,
.slick-next:focus,
.slick-next:active {
  background-color: #e8e8e8;
  outline: none;
}


.slick-prev,
.slick-next {
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 1;
  display: flex;
  border: 2px solid #4a4a4a;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s, background-color 0.3s;
  transform-origin: center center;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 24px;
  font-weight: 900;
  line-height: 1;
  opacity: 0.75;
  color: #4a4a4a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-prev:hover,
.slick-next:hover {
  background-color: white;
}



.slick-prev {
  left: -20px;
}

[dir='rtl'] .slick-prev {
  right: -20px;
  left: auto;
}

.slick-prev:before {
  content: '<';
}

[dir='rtl'] .slick-prev:before {
  content: '>';
}

.slick-next {
  right: -20px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -20px;
}

.slick-next:before {
  content: '>';
}

[dir='rtl'] .slick-next:before {
  content: '<';
}

.slick-dots {
  position: absolute;
  bottom: -10px;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.25;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
}

@media (max-width: 600px) {
  .slick-prev, .slick-next {
    z-index: -1;
  }
} 