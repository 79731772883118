.header-club {
  background-color: #1d1d1e;
  text-align: center;
  padding: 2rem;
  box-shadow: 2px 5px 7px 0 rgba(0, 0, 0, 0.3);
}

.title-about {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  font-family: "Klima", sans-serif;
}

.subtitle {
  margin-bottom: 10px;
  color: #929292;
  font-size: 15px;
}

.container-club {
  text-align: center;
  padding: 2rem;

  .title-club {
      font-size: 30px;
      font-weight: 700;
      color: #1d1d1e;
      line-height: 1;
  }

  .subtitle-club {
      font-size: 13px;
      color: #49494b;
  }

  .content-club {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #929292;

      .left-image-club,
      .right-image-club {
          flex-basis: 100%;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
              padding: 1rem;
              max-width: 60%;
              max-height: auto;
              object-fit: contain;
              transition: transform 0.3s ease;
          }

          img:hover {
              transform: scale(1.1);
          }

          .description-club {
              color: #929292;
              font-weight: 500;
              margin-top: 10px;
              display: flex;
              justify-content: center;
              text-decoration: none;
              transition: color 0.3s ease;
          }
          .description-club:hover {
              color: #d02129;
          }
      }
  }
}

/* Media Queries for Mobile Devices */
@media only screen and (max-width: 768px) {
  .header-club {
      padding: 1rem;
  }

  .title-about {
      font-size: 24px;
  }

  .container-club {
      padding: 1rem;

      .title-club {
          font-size: 24px;
      }

      .content-club .left-image-club,
      .content-club .right-image-club {
          flex-basis: 100%;
      }

      .description-club {
          font-size: 12px; /* Adjust the font size as per your preference */
      }
  }
}
