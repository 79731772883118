$primary-color: #1d1d1e;

.fullscreen-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.photo-gallery {
    background: $primary-color;
}

.close-button,
.nav-button {
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.close-button {
    position: fixed;
    background: transparent;
    top: 10px;
    color: #929292;
    right: 10px;
    transition: transform 0.3s;
  }
  
  .close-button:hover {
    transform: scale(1.2);
  }
  
  

.close-button:hover,
.nav-button:hover {
  color: #fff;
}

.nav-button {
  background: none;
  color: #929292;
}

.prev-button {
    padding: 1rem 3rem;
    font-size: 30px;
    font-family: "Klima", sans-serif;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%);
    transition: left 0.3s ease-in;
}
  
.prev-button:hover {
    animation: left 2s infinite;
  }
  @keyframes left {
    0% {
      left: 10px;
    }
    50% {
      left: 20px;
    }
    100% {
      left: 10px;
    }
  }

  .next-button {
    padding: 1rem 3rem;
    font-size: 30px;
    font-family: "Klima", sans-serif;
    font-weight: 600;
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    transform: translateY(-50%);
    transition: right 0.3s ease-in;
}
  
.next-button:hover {
    animation: right 2s infinite;
  }
  @keyframes right {
    0% {
        right: 10px;
    }
    50% {
        right: 20px;
    }
    100% {
        right: 10px;
    }
  }

.fullscreen-image {
  width: auto;
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Added box shadow */
  transition: transform 0.3s; /* Added transition for smooth scaling */
}

.fullscreen-image:hover {
  transform: scale(1.05); /* Slightly scaled up on hover */
}

.gallery-container {
  padding: 10px;
}

.gallery-image {
  width: 100%;
  display: block;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Added box shadow */
  transition: transform 0.3s; /* Added transition for smooth scaling */
}

.gallery-image:hover {
  transform: scale(1.05); /* Slightly scaled up on hover */
}

  .skeleton-loading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .skeleton-image {
      width: calc(33.33% - 10px); // Adjust the width based on the number of columns
      height: 300px; // Adjust the height as needed
      margin-bottom: 20px;
      background-color: #e0e0e0; // Placeholder background color
      animation: skeleton-pulse 1.5s infinite;
  
      &:nth-child(2n + 1) {
        animation-delay: 0.2s;
      }
  
      &:nth-child(2n) {
        animation-delay: 0.4s;
      }
    }
  
  }
  
  @keyframes skeleton-pulse {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.8;
    }
  }
  