// PowerMatrix.scss
.power-matrix-container {
  display: flex;
  height: auto;
  padding: 3rem 20rem;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding: 3rem 2rem;
  }

  .power-matrix-table {
    border-collapse: collapse;
    width: 100%;
    justify-content: center;
    border: 1px solid black;
  }
}

.table-paragraph {
  color: #929292;
  padding: 10px 0 2rem 0;
  text-align: center;
}

.additional-paragraph {
  color: black;
  line-height: 2;
  text-align: justify;
  margin-bottom: 1em;
  justify-content: flex-start;
}

.table-title {
  color: black;
  font-size: 50px;
  padding-top: 2em;
  text-align: center;
}

.multiplier-container {
  background-color: #1d1d1e;
  position: relative;
  width: 100%;
  padding: 10rem;

  @media (max-width: 1200px) {
    padding: 2rem;
  }
}

.multiplier {
  padding: 0 3rem;

  @media (max-width: 1200px) {
    padding: 0 1rem;
  }

  .additional-paragraph2 {
    color: #929292;
    line-height: 2;
    text-align: justify;
    margin-bottom: 1em;
    justify-content: flex-start;
  }

  .table-title2 {
    color: #f2f2f2;
    font-size: 50px;

    @media (max-width: 1200px) {
      font-size: 30px;
    }

    padding-top: 2em;

    @media (max-width: 1200px) {
      padding-top: 1em;
    }

    text-align: center;
  }
}

.power-matrix-table {
  &.full-screen-image {
    cursor: zoom-out;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.power-matrix-container.full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;

  .power-matrix-table {
    cursor: zoom-out;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}


.rankings-roundnet {
  background-color: #1d1d1e;
  justify-content: center;
  height: auto;
  padding: 2rem;

  .rankings-header {
    text-align: center;
    margin-bottom: 50px;
    line-height: 1;

    .title-rankings {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      font-family: "Klima", sans-serif;
    }
  }

  .content-rankings {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 90vw;
    align-items: center;
    justify-content: center;

    .img {
      display: flex;
      justify-content: center;
      img {
        width: 90%;
        height: auto;
      }
    }

    .rankings-description {
      flex: 1;
      line-height: 3;
      margin-left: 20px;

      p {
        margin-bottom: 2rem;
      }

      .rankings-paragraph {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 1200px) {
    .content-rankings {
      flex-direction: column;
      max-width: 100%;
      position: relative;

      .video,
      .rankings-description {
        margin-left: 0;
      }

      .rankings-description {
        margin-top: 2rem;
      }
    }
  }
}