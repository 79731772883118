.events {
    width: 100%;
    background-color: #eaeaea;
    padding-bottom: 10rem;
      .header {
      text-align: center;
      margin-bottom: 50px;
      line-height: 1;
      padding-top: 5rem;
  
    .title {
        color: #1d1d1e;
        font-family: "Klima", sans-serif;
        font-size: 50px;
        font-weight: 600;
      }
      .subtitle {
        color: #929292;
        font-size: 15px;
      }
  }
}
.event-container {
    max-width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  
  .item-container {
    position: relative;
    margin: 24px;
    width: 320px;
    height: 570px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  
  .img-container,
  .body-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .img-container {
    background-size: cover;
    background-position: center;
  }
  
  .body-container {
    position: relative;
  }
  
  .overlay {
    position: relative;
    width: 100%;
    height: 400px;
    background-color: rgba(208, 33, 42, 0.237);
    opacity: 0;
    transition: height linear 0.4s, opacity linear 0.2s;
  }
  
  .item-container:hover .overlay {
    opacity: 1;
    height: 150px;
  }
  
  .event-info {
    background-color: #fff;
    display: flex;
    color: #1d1d1e;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  
  .title-event {
    color: #1d1d1e;
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .location {
    color: #1d1d1e;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
  
  .date {
    letter-spacing: 0.5px;
    color: #1d1d1e;
    font-size: 0.8rem;
    margin-bottom: 3rem;
    
  }
  
  .separator {
    width: 20%;
    height: 2px;
    background-color: #1d1d1e;
    margin-bottom: 10px;
  }
  
  .additional-info {
    border-top: 1px solid #1d1d1e;
    margin-top: 12px;
    font-weight: 300;
    padding: 28px;
    font-size: 15px;
    line-height: 1rem;
    padding-bottom: 0;
  }
  
  .additional-info .info-description {
    font-size: 0.9em;
    margin-bottom: 20px;
    color: #1d1d1e;
  }
  
  .action {
    color: #fff;
    border: 3px solid #fff;
    background-color: transparent;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    outline: none;
    cursor: pointer;
    padding: 12px;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 2px;
    transition: background-color 0.4s, top 0.4s;
  }
  
  .item-container:hover .action {
    top: 50px;
  }
  
  .action:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .map-marker-icon svg {
    fill: black;
    margin-right: 5px;
  }
  

  @media (max-width: 1000px) {
    .sanction-container{
      display: none;
    }
  }