.gallery-first {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px;
  background-color: #111111;
}
.gallery-content {
  max-width: 600px;
  margin-bottom: 20px;
}

.gallery-header {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
  line-height: 1;
  max-width: 400px;
}


.gallery-list {
  margin-top: 20px;
}

.gallery-note {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.gallery-text16{
  font-size: 18px;
  margin: 0;
  color: #929292;
}

.gallery-image05 {
  width: 35%;
  margin-left: 40px;
  top: -100px;
  position: relative;
}



.gallery-image05:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.learn-more-link {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
  display: inline-block;
}

.learn-more-link::after {
  content: " →";
  margin-left: 5px;
  transition: margin-left 0.3s ease-in;
}

.learn-more-link:hover::after {
  animation: margin 2s infinite;
}
@keyframes margin {
  0% {
    margin-left: 10px;
  }
  50% {
    margin-left: 0px;
  }
  100% {
    margin-left: 10px;
  }
}
@media (max-width: 1154px) {
  .gallery-first {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .gallery-content {
    padding: 0 5rem;
    top: -100px;
    position: relative;
  }

  .gallery-image05 {
    width: 50%;
    height: auto;
    object-fit: contain;
    margin: 0;
  }
  .gallery-header {
    font-size: 30px;
  }
  .gallery-text16, .gallery-text17 {
    font-size: 12px;
    line-height: 2;
  }
}
