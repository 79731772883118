.faq-section-container {
    display: flex;
    justify-content: center;
    padding: 50px 0 100px;
    background-color: #1d1d1e;
  }
  

.faq-section__answer-text
{
  span {
    color: #888;
    a {
      color: #b1b1b1;
    }
  }
}
  .faq-section {
    width: 100%;
    max-width: 1200px;
    background-color: transparent;
    color: white;
  }
  
  .faq-section__title {
    font-size: 50px;
    font-weight: 600;
    font-family: "Klima", sans-serif;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .faq-section__subtitle {
    font-size: 15px;
    color: #888;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 5rem;
  }
  
  .faq-section__accordion {
    .faq-section__item {
      background-color: transparent;
      padding: 16px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &.active {
        .faq-section__question {
          font-weight: bold;
        }
  
        .faq-section__answer {
          max-height: 200px;
          opacity: 1;
          visibility: visible;
          transition: max-height 0.3s ease, opacity 0.3s ease;
        }
  
        .faq-section__arrow {
          transform: rotate(90deg);
        }
      }
  
      &:hover {
        background-color: #ffffff1a;
      }
    }
  
    .faq-section__question {
      font-weight: normal;
      position: relative;
      padding-bottom: 3rem;
      display: flex;
      align-items: center;
      font-size: 20px;
  
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #49494b;
      }
    }
  
    .faq-section__answer {
      max-height: 0;
      opacity: 0;
      font-size: 15px;
      visibility: hidden;
      overflow: hidden;
      transition: max-height 0.3s ease, opacity 0.3s ease;
    }
  
    .faq-section__answer-text {
      margin-top: 30px;
      color: #888;
    }
  
    .faq-section__arrow {
      font-size: 18px;
      transition: transform 0.3s ease;
      transform-origin: center;
      margin-left: auto;
    }
  
    .faq-section__item.active .faq-section__arrow {
      transform: rotate(90deg);
    }
  }
  
  @media (max-width: 860px) {
    .faq-section__title {
        font-size: 30px;
    }
    .faq-section__subtitle {
      font-size: 10px;
  }
  .faq-section__accordion .faq-section__item.active .faq-section__answer {
    max-height: 400px;
  }
  .faq-section__accordion .faq-section__item {
    padding: 30px;
  }
}