.event-section {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/13.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
    filter: brightness(0.5);
    transition: opacity 0.5s ease;
    opacity: 1;
  }

  &:not(.scrolled)::before {
    opacity: 0;
  }

  .event-section__see-all {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    background: #d02129;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    border-radius: 0;
    transition: letter-spacing 0.3s, color 0.3s;

    &:hover {
      letter-spacing: 2px;
      color: white;
    }
  }

  &.scrolled::before {
    opacity: 1;
    transition-delay: 0.5s;
  }

  .event-section__content {
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
  }

  .event-section__title {
    text-align: center;
    font-weight: 600;
    line-height: 1.3;
    font-family: 'Klima', sans-serif;
    font-size: 50px;
    
  }

  .event-section__paragraph {
    text-align: center;
    font-size: 15px;
    margin-bottom: 50px;
    color: white;
  }

  .event-section__event-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .event-section__event {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #111111;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: #d02129;
    }
  }

  .event-section__event-date {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .event-section__event-date-inner {
    width: 100%;
    height: 100%;
    display: flex;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    line-height: 1;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .event-section__event-number {
    color: whitesmoke;
    font-size: 15px;
  }

  .event-section__event-month {
    font-size: 38px;
    font-weight: bold;
  }

  .event-section__event-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .event-section__event-title {
    margin: 0;
    font-size: 18px;
  }

  .event-section__event-description {
    margin: 5px 0;
  }

  .event-section__event-see-more {
    display: inline-block;
    color: #d02129;
    padding: 5px 0;
    text-decoration: none;
    border-radius: 5px;
    background: none;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background-color: #d02129;
      transition: left 0.3s ease;
    }

    &:hover::before {
      left: 0;
    }
  }

}
@media (max-width: 840px) {
  .event-section .event-section__title {
    font-size: 30px;
  }
  .event-section .event-section__paragraph {
    font-size: 10px;
  }
  .event-section .event-section__event-month {
    font-size: 32px;
  }
  .event-section .event-section__event-number {
    font-size: 10px;
  }
  .event-section .event-section__content {
    margin: 20px 10px;
  }
}