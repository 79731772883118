.calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    height: 100vh;
    width: 100vw;
    background-color: #1d1d1e;
    color: #ffffff;
    padding: 20px;
  }
  
  .calendar-container {
    background-color: #ffffff1a;
    padding: 2rem;
  }
  
  .calendar__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Klima", sans-serif;
    h1 {
      font-weight: 600;
    }
  }
  
  .calendar__nav-button {
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    margin: 30px;
  }
  
  .calendar__month-year {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 50px;
}
  
  .calendar__weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    text-align: center;
  }
  
  .calendar__day-of-week {
    padding: 10px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
    width: 150px;
    height: 50px;
    align-items: center;
  }
  
  .calendar__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
  }
  
  .calendar__day {
    padding: 10px;
    text-align: left;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 0.2s;
    position: relative;
    font-size: 13px;
    height: 100px;
    border: 0.2px solid #0b0b0b1b;
    &.prev-day,
    &.next-day {
      color: #888;
    }
  }
  
  .calendar__day-number {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.8rem;
  }
  
  .calendar__day:hover {
    background-color: #323233;
  }
  
  .calendar__day--today {
    font-weight: bold;
    background-color: #888;
    color: #1d1d1e;
  }
  
  .calendar__today-button {
    font-size: 15px;
    border: 2px solid #888;
    background-color: transparent;
    color: #888;
    border-radius: 10px;
    padding: 0.5rem;
    cursor: pointer;
    outline: none;
  }
  
  .calendar__event-bar {
    background-color: #F3F3F3;
    color: #929292;
    height: 20px;
    padding: 4px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 2;
    border: 1px solid #ffffff;
  }
  

  @media (max-width: 1000px) {
    .calendar{
      display: none;
    }
  }