.card-containerr
{
    background-color: #e4e4e4;
}

.card-header {
    background-color: #e4e4e4;
    text-align: center;
    font-family: "Klima", sans-serif;
    h1 {
        padding: 5rem 5rem 0 5rem;
        color: #1d1d1e;
        font-weight: 600;
        font-size: 50px;
    }
    p {
        color: #1d1d1e;
        font-size: 15px;
    }
}
.card-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    
    .card {
        position: relative;
        flex: 1 0 300px; /* Allow cards to grow up to 300px */
        max-width: 300px;
        width: 300px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
        padding: 16px;
        margin:  56px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease-in-out;
    }
    .card:hover {
        transform: scale(1.05)
      }
    .card-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 15px 35px -15px rgba(0, 0, 0, 0.5),
        0 5px 20px 0 rgba(0, 0, 0, 0.1);
    }
    
    .card-image img {
        width: 100%;
        height: auto;
        transform: scale(1.2);
    }
    
    p {
        line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    text-align: center;
    color: #333;
}

.name {
    margin-top: 80px;
    font-size: 1.4em;
    color: #1d1d1e;
    font-weight: bold;
    letter-spacing: 0.8px;
    margin-bottom: 8px;
}

.socials {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    margin: 12px 8px;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.4s;
}

button:hover {
    opacity: 0.8;
}

button i {
    color: #fff;
    font-size: 1.4em;
}

button.facebook {
    background-color: #24292e;
} 

button.twitter {
    background-color: #1da1f2;
}

button.instagram {
    background-color: #d3263a;
} 
}