@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --orange: #ff8139;
  --pink: #ff3998;
  --green: #39ffa0;
  --red: #ff3939;
  --light-green: #edfff6;
  --light-orange: #fff0e8;
  --accent-color1: #ffffff;
  --accent-color2: #555555;
  --accent-color3: #cccccc;
  --card-color: #000000;
  --background: #111111;
  --font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  color: #fff;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  font-family: var(--font-family);
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

::-webkit-scrollbar {
  background-color: var(--background);
  width: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background-color: #D02129;
}

@media (max-width: 575.98px) {
  .App {
    padding: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .App {
    padding: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
