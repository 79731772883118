.circle-cursor {
  position: fixed;
  z-index: 9999;
  width: 10px;
  height: 10px;
  border-color: transparent;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.2s ease;
  transform-origin: center;
}

.circle-cursor.hover {
  background-color: transparent;
  border: 1px solid red;
  border-radius: 50%;
  transform: scale(3);
}

@media (max-width: 900px) {
  .circle-cursor {
    display: none;
  }
}
