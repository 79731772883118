.about-roundnet {
  background-color: #1d1d1e;
  justify-content: center;
  height: auto;
  .about-net {
    padding: 2em;
  }
  .header {
    text-align: center;
    line-height: 1;

    .title-about {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      font-family: "Klima", sans-serif;
    }

    .title-gameplay {
      color: #1d1d1e;
      font-family: "Klima", sans-serif;
      font-size: 50px;
      font-weight: 600;
    }

    .subtitle {
      margin-bottom: 10px;
      color: #929292;
      font-size: 15px;
    }
  }

  .content-about {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100vw;
    .img {
      justify-content: center;
      display: flex;
      align-items: center;
      max-width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .video {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      iframe {
        width: 560px;
        height: 315px;
      }
    }

    .description {
      flex: 1;
      line-height: 3;
      margin: 50px;
      p {
        margin-bottom: 2rem;

        span {
          margin-left: 50px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    flex-direction: column;

    .content-about {
      flex-direction: column;
      max-width: 100%;
      position: relative;
      .img {
        img {
          width:50%;
        }
      }
      .description {
        margin-top: 2rem;
      }

    }
    .container-card {
      flex-direction: column;
    }
  }
}

.gameplay-section {
  background-color: #e4e4e4;
  position: relative;
  padding: 2em;
}

.container-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .cards {
    margin: 2em;
    justify-content: center;
    .face {
      width: 300px;
      height: 300px;
      transition: 0.5s;

      &.face1 {
        position: relative;
        background: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        transform: translateY(150px);

        .content {
          opacity: 0.2;
          transition: 0.5s;

          img {
            max-width: 100px;
          }

          h3 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 40px;
          }
        }
      }

      &.face2 {
        position: relative;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
        transform: translateY(-150px);

        .content {
          p {
            margin: 0;
            padding: 1.5rem;
            color: #1d1d1e;
            text-align: center;
            font-size: 15px;
          }

          a {
            margin: 15px 0 0;
            display: inline-block;
            text-decoration: none;
            font-weight: 900;
            color: #333;
            padding: 5px;
            border: 1px solid #333;
          }

          a:hover {
            background: #333;
            color: #fff;
          }
        }
      }
    }
  }

  .cards:hover {
    .face.face1 .content {
      opacity: 1;
    }

    .face.face1 {
      background: #49494b;
      transform: translateY(0);
    }

    .face.face2 {
      transform: translateY(0);
    }
  }

  @media (max-width: 1400px) {
    .container-card {
      align-items: center;
      flex-direction: column;

      .face {
        width: 100%;
        height: auto;
      }

      .face.face1 {
        transform: translateY(0);
      }

      .face.face2 {
        transform: translateY(0);
      }
    }
  }
}

.about-roundnet {
  .details-net {
    transition: background-color 0.3s ease;
    padding: 2em;
    &:hover {
      background-color: #49494b;
      .description {
        p {
          color: white;
        }
      }
    }
  }
}

.values {
  background-color: #1d1d1e;
  min-height: 100vh;
  width: 100%;

  .container-values {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    .cards {
      display: flex;
      margin: 1em 3em;
      flex-wrap: wrap;
      justify-content: center;
      .card {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 1rem;
        width: 350px;
        height: 300px;
        background: #49494b;
        box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
        transition: 0.5s;

        .imgBx {
          position: absolute;
          top: 20px;
          width: 300px;
          height: 220px;
          overflow: hidden;
          transition: 0.5s;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content-values {
          position: absolute;
          top: 210px;
          padding: 0 3em 1em 3em;
          height: 60px;
          overflow: hidden;
          transition: 0.5s;

          h2 {
            font-size: 3em;
            font-weight: 700;
            color: #929292;
            text-align: center;
            text-transform: uppercase;
            transition: color 0.3s;
          }

          p {
            padding-top: 1.5em;
            color: #e4e4e4;

            span {
              font-weight: 600;
            }
          }

          ul {
            margin-left: 2em;
          }
        }
      }

      &:hover {
        .card {
          height: 600px;

          .imgBx {
            top: -100px;
          }

          .content-values {
            top: 130px;
            height: 450px;
          }

          h2 {
            color: #e4e4e4;
          }
        }
      }
    }
  }
}

.header-val {
  text-align: center;
  padding: 5rem;
  line-height: 1;

  .title-about {
    font-size: 3.125em;
    font-weight: 600;
    color: #fff;
    font-family: "Klima", sans-serif;
  }

  .title-gameplay {
    color: #1d1d1e;
    font-family: "Klima", sans-serif;
    font-size: 50px;
    font-weight: 600;
  }

  .subtitle {
    margin-bottom: 10px;
    color: #929292;
    font-size: 15px;
  }
}

.download-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 10rem;
  box-shadow: inset 0px 15px 20px 0px rgba(0, 0, 0, 0.2);

  .title-rules {
    order: 1;
    margin-right: 20px;
    font-family: "Klima", sans-serif;

    h2 {
      font-size: 40px;
      font-weight: 600;
    }

    p {
      font-size: 20px;
      color: #e4e4e4;
    }
  }

  .button-container {
    order: 2;
    border: 2px solid #fff;
    padding: 1rem 3rem;

    a {
      text-decoration: none;
    }

    .button-download {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 400;
      transition: all 0.4s ease;
      letter-spacing: 0;
      white-space: nowrap;

      &:hover {
        font-weight: 600;
        letter-spacing: 2px;
      }
    }
  }
}

.laws {
  background-color: #1d1d1e;
  z-index: 1;
  display: flex;
}

.guidelines {
  background-color: #d02129;
  z-index: 0;
}

@media (max-width: 1400px) {
  .laws,
  .guidelines {
    flex-direction: column;
    .button-container {
      margin: 2em;
    }
  }
  .values {
    .container-values {
      .cards {
        .card {
          .imgBx {
            max-width: 100%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
