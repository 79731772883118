.team-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #1d1d1e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    height: 50vh;
    border-radius: 20px;
    margin: auto;
    background-image: url('../img/15.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in;
    filter: brightness(0.5);
    z-index: 1;
  }
  
  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    padding: 20px;
    z-index: 2;
  }
  
  .title {
    font-size: 50px;
    font-weight: 600;
    font-family: "Klima", sans-serif;
  }
  
  .description {
    font-size: 15px;
    margin-bottom: 20px;
    color: white;
  }
  
  .link {
    font-size: 15px;
    text-decoration: none;
    color: white;
    background-color: #d02129;
    border: 2px solid #d02129;
    border-radius: 20px;
    padding: 10px 40px;
    transition: background-color 0.3s ease-in, letter-spacing 0.3s ease-in;
  }
  
  .link:hover {
    background-color: transparent;
    letter-spacing: 2px;
  }
  
  .background-img:hover {
    animation: zoom-in 2s infinite;
  }
  
  @keyframes zoom-in {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @media (max-width: 840px) {
    .team-section {
      .title {
        font-size: 30px;
      }
      .description {
        font-size: 10px;
      }
    }
  }