.contact-container {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181818;
    font-family: "Klima", sans-serif;
  }
  
  .container-contact {
    width: 85%;
    background: #fff;
    border-radius: 6px;
    padding: 50px 90px 50px 80px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    top: -100px;
    position: relative;
  }


  .sent-message {
    font-size: 16px;
    font-weight: 600;
    color: black;
    text-align: center;
    margin-top: 10px;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .right-side form {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.5s ease;
  }
  
  .right-side form.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  .right-side .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .container-contact .content-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .container-contact .content-contact .left-side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  
  .content-contact .left-side::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #1d1d1e;
  }
  
  .content-contact .left-side .details {
    margin: 14px;
    text-align: center;
  }
  
  .content-contact .left-side .details .topic {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1e;
    padding-top: 0.8em;
  }
  
  .content-contact .left-side .details .text-one,
  .content-contact .left-side .details .text-two {
    font-size: 14px;
    color: #1d1d1e;
  }
  
  .container-contact .content-contact .right-side {
    width: 75%;
    margin-left: 75px;
    p {
      color: #929292;
    }
  }
  
  .content-contact .right-side .topic-text {
    font-size: 23px;
    font-weight: 600;
    color: #1d1d1e;
  }
  
  .right-side .input-box {
    height: 50px;
    width: 100%;
    margin: 12px 0;
    position: relative;
  }
  
  .right-side .input-box input,
  .right-side .input-box textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F8F8F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
    color: #1d1d1e;
  }
  
  .right-side .input-box label {
    position: absolute;
    top: 0;
    left: 15px;
    font-size: 12px;
    color: #1d1d1e;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .right-side .input-box input:focus + label,
  .right-side .input-box textarea:focus + label,
  .right-side .input-box input:valid + label,
  .right-side .input-box textarea:valid + label {
    font-size: 10px;
    top: -10px;
    left: 5px;
    color: #49494b;
  }
  
  .right-side .message-box {
    min-height: 110px;
  }
  
  .right-side .input-box textarea {
    padding-top: 6px;
  }
  
  .right-side .button {
    display: inline-block;
    margin-top: 12px;
  }
  
  .right-side .button input[type="submit"] {
    color: #fff;
    font-size: 15px;
    padding: 1rem 2rem;
    outline: none;
    border: none;
    border-radius: 10px;
    background: #1d1d1e;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .button input[type="submit"]:hover {
    background: #49494b;
  }
  
  @media (max-width: 950px) {
    .container-contact {
      width: 90%;
      padding: 30px 40px 40px 35px;
      position: relative;
    }
    .contact-container {
      height: 100vh;
      top: 0;
    }
  
    .container-contact .content-contact .right-side {
      width: 75%;
      margin-left: 55px;
    }
  }
  
  @media (max-width: 820px) {
    .container-contact {
      margin: 40px 0;
      height: auto;
    }
  
    .container-contact .content-contact {
      flex-direction: column-reverse;
    }
  
    .container-contact .content-contact .left-side {
      width: 100%;
      flex-direction: column;
      margin-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .container-contact .content-contact .left-side::before {
      display: none;
    }
  
    .container-contact .content-contact .right-side {
      width: 100%;
      margin-left: 0;
    }
  }
  
  @media (max-width: 840px) {
    .container-contact .content-contact .right-side p {
      font-size: 10px;
    }
    .right-side .button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
  }