.members-container {
    display: flex;
    height: 100vh;
  }
  
  .descriptions-members {
    flex: 1;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .members{
        padding: 5em;
        
    }
  }
  
  .title-members {
    color: #1d1d1e;
    font-size: 50px;
  }
  
  .subtitle {
    font-size: 15px;
    color: #929292;
    margin-bottom: 30px;
  }
  
  .descriptions {
    margin-bottom: 20px;
    color: #49494b;
    line-height: 3.5;
  }

  
  .image {
    flex: 1;
    background-color: #ccc;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  }
  
  .image-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image:hover {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); /* Update box shadow on hover */
  }
  
  

  
.read-more {
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1e;
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;
  }
  
  .read-more::after {
    content: " →";
    margin-left: 5px;
    transition: margin-left 0.3s ease-in;
  }
  
  .read-more:hover::after {
    animation: margin 2s infinite;
  }
  @keyframes margin {
    0% {
      margin-left: 10px;
    }
    50% {
      margin-left: 0px;
    }
    100% {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    .members-container {
      flex-direction: column;
      align-items: center;
    }
  
    .descriptions-members {
      align-items: center;
      text-align: center;
      padding: 2em;
    }
  
    .subtitle {
      margin-bottom: 20px;
    }
  
    .image {
      display: none;
    }
  }

  .members-header {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 5rem;
    line-height: 1;
    padding: 5rem;

    .members-title {
      font-size: 50px;
      font-weight: 600;
      color: #1d1d1e;
      font-family: "Klima", sans-serif;
    }
    .members-subtitle {
      margin-bottom: 10px;
      color: #929292;
      font-size: 15px;
    }
}