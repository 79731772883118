.timeline-container {
  background: #1d1d1e;
  padding: 5rem 0;
}

.header {
  text-align: center;

  h2 {
    font-size: 50px;
    color: #ffffff;
  }

  p {
    font-size: 15px;
    color: #929292;
  }
}

.timeline {
  margin: 0 auto;
  max-width: auto;
  width: 90%;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 3px 1fr;
  color: #ffffff;
  background-color: #1d1d1e;
}

.timeline__component {
  margin: 0 20px 20px 20px;
}

.timeline__component--bg {
    padding: 1.5em;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .timeline__component--bg:hover {
    transform: scale(1.05);
  }
  

.timeline__component--bottom {
  margin-bottom: 0;
}

.timeline__middle {
  position: relative;
  background: #ffffff;
}

.timeline__point {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  background: #ffffff;
  border-radius: 50%;
}

.timeline__point--bottom {
  top: initial;
  bottom: 0;
}

.timeline__date--right {
  text-align: right;
}

.timeline__title {
  margin: 0;
  font-size: 1.15em;
  font-weight: bold;
}

.timeline__paragraph {
  line-height: 2;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #929292;
  font-size: 12px;
  transition: color 0.3s ease-in-out;
}

.timeline__component--bg:hover .timeline__paragraph{
    color: whitesmoke;
  }

  .video-container2 {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
      .video {
        width: 90%;
        iframe {
          width: 100%;
        }
      }
    .video-header {
      text-align: center;
      padding: 3rem;
    
      h2 {
        font-size: 50px;
        color: #ffffff;
      }
    
      p {
        font-size: 15px;
        color: #929292;
      }
    }
  }

  @media (max-width: 800px) {
    .timeline-container {
      .timeline {
        grid-template-columns: unset; 
      }
      .timeline__point,
      .timeline__date--right,
      .timeline__date {
        display: none;
      }
      .video-container2 {
        .video {
          width: 90%;
          iframe {
            width: 100%;
          }
        }
      }
    }
  }