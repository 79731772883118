.scrollTop {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 100;
  bottom: 5%;
  right: 5%;
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  border: solid 3px #D02129;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--background) inset;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    svg {
      font-size: 1.5rem;
      color: var(--accent-color1);
    }
  }
}

.visible {
  visibility: visible;
  opacity: 1;
}
