nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 10rem;
}

.brand-container {
  .brand {
    img {
      height: 100%;
      width: 10rem;
      height: auto;
    }
  }
  .toggle-container {
    display: none;
  }
}

.links-container {
  .links {
    list-style: none;
    display: flex;
    gap: 2rem;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      cursor: pointer;
      .join-us {
        background-color: #D02129;
        border-radius: 50px;
        padding: 0.5rem 1rem;
        display: inline-block;
        text-decoration: none;
        color: white;
        font-weight: 600;
        transition: background-color 0.3s ease, letter-spacing 0.3s ease-in;
        white-space: nowrap;
      }

      .join-us:hover {
        color: white;
        text-decoration: none;
        letter-spacing: 0.5px;
      }
      .join-us::after {
        display: none;
      }

      a {
        text-decoration: none;
        color: var(--accent-color1);
        position: relative;
        transition: color 0.3s ease;
      }

      a::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background-color: #D02129;
        transition: width 0.3s ease;
      }

      a:hover {
        color: #D02129;
      }

      a:hover::after {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575.98px) {
  nav {
    position: relative;
    padding: 2rem;
  }

  .brand-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .brand {
      img {
        height: 1.5rem;
        width: 5rem;
        height: auto;
      }
    }
    .toggle-container {
      display: block;
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;
      color: var(--accent-color1);
      z-index: 40;
    }
  }

  .links-container {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    transition: 0.5s ease-in-out;
    background-color: #D02129;
    .links {
      flex-direction: column;
      /* Remove the "display: none" from the last list item */
      /* li:last-of-type {
        display: none;
      } */
    }
  }

  .nav-visible {
    width: 60vw;
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  nav {
    padding: 1rem 2rem;

    .links-container {
      .links {
        gap: 2rem;

        li {
          font-size: 14px;
        }
      }
    }
  }
}
