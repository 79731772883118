.home-image06 {
  width: 50%;
  height: auto;
  padding: 5em;
  transition: transform 0.2s ease;
}

.home-image06:hover {
  transform: scale(1.1);
}

.video-container {
  position: relative;
  width: 50%;
  height:auto;
  cursor: pointer;
}

.home-image05 {
  width: 100%;
  height: 100%;
}

.play-pause-icon {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  opacity: 0; 
  transition: opacity 0.3s ease;
  
}

.play-pause-icon.visible {
  opacity: 1;
}

.play-pause-icon.hidden {
  opacity: 0;
}


.home-first {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #111111;
}

.home-content {
  max-width: 600px;
  margin-bottom: 20px;
}

.home-header {
  font-family: 'Klima', sans-serif;
  font-size: 3.125em;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
  line-height: 1.5;
  max-width: 400px;
}


.home-list {
  margin-top: 20px;
}

.home-note {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.home-note1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.home-point,
.home-point01 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin-right: 10px;
}

.home-text16,
.home-text17 {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  color: #fff;
}

.home-image05 {
  margin-left: 40px;
  max-width: 100%;
  height: 100vh;
  object-fit: contain;
}

.learn-more-link {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  margin-top: 20px;
  display: inline-block;
}

.learn-more-link::after {
  content: " →";
  margin-left: 5px;
  transition: margin-left 0.3s ease-in;
}

.learn-more-link:hover::after {
  animation: margin 2s infinite;
}
@keyframes margin {
  0% {
    margin-left: 10px;
  }
  50% {
    margin-left: 0px;
  }
  100% {
    margin-left: 10px;
  }
}


@media (max-width: 930px) {
  .video-container {
    width: 50%;
  }

  .home-content {
    margin-right: 40px;
  }

  .home-image05 {
    height: 100%;
    object-fit: contain;
    margin: 0;
  }
  .home-first {
    height: auto;
    padding: 2rem;
    flex-direction: column;
  }
  .home-text16, .home-text17 {
    font-size: 12px;
    line-height: 2;
  }
}

@media (max-width: 522px) {
  .video-container {
    width: 100%;
  }
}