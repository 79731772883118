.headers {
  h1 {
    width: 100%;
    height: 30vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  
  
    .title {
      font-size: 3rem;
      color: #fff;
    }
  }

  .header-team {
    background-image: url('../img/1.png');
    background-size: cover;
    background-position: center;
  }
  .header-events {
    background-image: url('../img/2.png');
    background-size: cover;
    background-position: center;
  }
  .header-gallery {
    background-image: url('../img/3.png');
    background-size: cover;
    background-position: center;
  }
  .header-learn {
    background-image: url('../img/4.png');
    background-size: cover;
    background-position: center;
  }
  .header-events {
    background-image: url('../img/5.png');
    background-size: cover;
    background-position: center;
  }
  .header-about {
    background-image: url('../img/6.png');
    background-size: cover;
    background-position: center;
  }
  .header-rankings {
    background-image: url('../img/7.png');
    background-size: cover;
    background-position: center;
  }
  .header-join {
    background-image: url('../img/8.png');
    background-size: cover;
    background-position: center;
  }