.subscribe-newsletter {
  background-color: #f8f8f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;

  .subscribe-newsletter__sent-message {
    font-size: 20px;
    color: #000; /* Set the font color to black */
    margin-bottom: 10px;
    text-align: center;
  }
  
  &__content {
    padding: 20px;
  }

  &__title {
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: "Klima", sans-serif;
    color: #1d1d1e;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
    color: #888;
    margin-bottom: 50px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1d1d1e;
  }

  &__form-group {
    display: flex;
    align-items: center;
    width: 300px;
    height: 50px;
    border-radius: 30px;
    overflow: hidden;
    background-color: #F3F3F3;
    transition: height 0.3s ease;

    & .subscribe-newsletter__input {
      flex: 1;
      padding-left: 20px;
      border: none;
      border-radius: 20px;
      outline: none;
      color: #929292;
      background-color: transparent;
      transition: opacity 0.3s ease;
      font-family: "Klima", sans-serif;
      margin-top: 0;
      &:focus {
        outline: none;
      }

      &.hidden {
        opacity: 0;
        width: 0;
        pointer-events: none;
      }
    }

    & .subscribe-newsletter__button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #b21a22;
      color: #fff;
      width: 50px;
      height: 50px;
      cursor: pointer;
      border-radius: 50%;
      transition: background-color 0.3s ease;
      transform: rotate(-45deg);
      font-size: 25px;
      padding: 0;
      margin-top: 0;
      &:hover {
        background-color: #ff2e2e;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__disclaimer {
    font-size: 8px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.2;
    text-align: left;
    display: flex;
    justify-content: center;
    color: #1d1d1e;
  }
}

.subscribe-newsletter__form-group.clicked {
  height: 0;
  padding: 0;
  margin: 0;
}

@media (max-width: 950px) {
  .subscribe-newsletter {
    height: 120vh;
  }
}
@media (max-width: 860px) {
  .subscribe-newsletter__title {
    text-align: center;
    font-size: 30px;
  }
  .subscribe-newsletter__subtitle {
    font-size: 10px;
  }
}