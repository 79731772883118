.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0F0F0F;
    transform-origin: bottom;
    z-index: 100000;
}

.slide-in::after,
.slide-out::after {
    content: "WELCOME";
    color: #fff;
    font-size: 30px;
    font-family: "Klima", sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0F0F0F;
    transform-origin: top;
    z-index: 100000;
}