.carousel-container {
  width: 80%;
}

.carousel-slide {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel-slide .image-container {
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 3px solid transparent;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.carousel-slide .image-date {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.carousel-slide .image-title {
  position: absolute;
  bottom: 8px;
  font-weight: 600;
  left: 8px;
  padding: 20px;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.carousel-slide .image-container:hover .image-title,
.carousel-slide:hover .image-date {
  opacity: 1;
}

.carousel-slide img {
  border-color: transparent;
  transition: border-color 0.3s, transform 0.3s;
}

.carousel-slide:hover img {
  border-color: #D02129;
  transform: scale(1.05) translateY(-3%);
}

.carousel-slide.active-slide img {
  border-color: #D02129; /* Colored border for active slide */
  transform: scale(1.05) translateY(-3%);
}

.carousel-slide.active-slide .image-date,
.carousel-slide.active-slide .image-title {
  opacity: 1; /* Make date and title visible for active slide */
}

@media screen and (max-width: 768px) {
  .carousel-container {
    width: 90%;
    padding: 0.5rem;
  }

  .carousel-slide .image-title {
    font-size: 12px;
    bottom: 4px;
    left: 4px;
    padding: 2px 4px;
  }

  .carousel-slide .image-date {
    font-size: 10px;
    top: 4px;
    left: 4px;
  }
}
